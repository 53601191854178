import React, { useState } from "react";
import { Box, TextField,Modal,Button,Typography } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import { saveSimulation } from "../../store/actions/SimulationActions";
import { ReactComponent as LogoGw } from "../../logo-GW-white.svg";
import { saveSimulationTextField,modalInfosStyle,styles} from "../../molecules/StyleMolecule";
import {AlertsAtom} from "../../atoms";

export const SaveModalAtom = () => {

  const dispatch = useDispatch();
  const savedData = useSelector((state) => state?.save?.data);
  const [open, setOpen] = useState(false);
  const [name,setName] = useState('');
  const [email,setEmail] = useState(''); 
  const [errored,setErrored] = useState(false);

  const handleSubmit = () => {
    if (name === '' || email === '' || !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) { return setErrored(true)};
      setErrored(false);
      dispatch(saveSimulation({name,email,token:localStorage.getItem('simulation'),save:true}));
      setOpen(false);
  };

  return (
    <Box sx={styles.saveModalAtomBox}>  
      { savedData.saved ? <Box sx={styles.center}> <AlertsAtom resultat='success' /><Button disabled>Simulation enregistrée</Button></Box> : <Button fullWidth sx={[styles.bgPrimary,styles.bold,{'&:hover':{background:'rgb(16,23,96)'}}]} variant='contained' onClick={() => setOpen(true)}>Enregistrer Ma Simulation</Button> }
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box component="form" autoComplete="off" sx={modalInfosStyle}>
          {errored ? <AlertsAtom  isEmpty={true} /> : ''}
            <Box><LogoGw  height={30} /></Box>
            <Typography variant="h6" component="h2">Enregistrez votre simulation !</Typography>
            <Box sx={[styles.col,styles.w300,styles.mt2]}>
              <TextField size='small' fullWidth type="text" onChange={(e) => setName(e.target.value)} value={name} sx={saveSimulationTextField} placeholder='Nom Prénom' />  
              <TextField size='small' fullWidth type="email" onChange={(e) => setEmail(e.target.value)} value={email} required sx={saveSimulationTextField} placeholder='email.sample@gmail.com' />
              <Button variant="filled" color="white" sx={[styles.bgPrimary,styles.bold,styles.bs10]} onClick={handleSubmit}>Enregistrer</Button>
            </Box>
          </Box>
        </Modal>
    </Box>
   
  );
}
