import EuroTwoToneIcon from "@mui/icons-material/EuroTwoTone";
import { TextField,Button,Box } from "@mui/material";
import { styles } from '../../molecules/StyleMolecule';

export const TjmFieldAtom = ({handleChange,tjm}) => {
    return (
                <Box sx={{display:'flex',flexDirection:'column',marginRight:5}}>
                         <label className="text-xl pr-10 text-primary-background font-bold" htmlFor="tjm">Taux Journalier</label>
                         <Box>
                            <TextField  name="tjm" type="number" onChange={handleChange} required id="tjm" className="font-extra-bold text-xl rounded text-primary-background" variant="standard" value={tjm}/>
                            <EuroTwoToneIcon className="text-primary-background"   />
                         </Box>
                         <Box className="flex pb-5">
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{borderRadius:7,boxShadow:10,marginTop:1}]} name="tjm" value={250} onClick={handleChange}>250€</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="tjm" value={500} onClick={handleChange}>500€</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="tjm" value={800} onClick={handleChange}>800€</Button>
                         </Box>
                </Box>

    )
   }
  
