import { Alert,Box } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import { AlertStyle } from "../molecules/StyleMolecule";

export const AlertsAtom = ({resultat,isLoading,isEmpty,simulation}) => {
    return (
        <Box sx={{display:'flex',justifyContent:'center'}}>
                <Box>
                            { resultat === "success" ? <Alert  sx={AlertStyle} severity='success'>Mail envoyé, veuillez vérifier vos mails</Alert> : ""}
                            { resultat === "error" ? <Alert  sx={AlertStyle} severity='error'>Vous devez au moins renseigner votre adresse mail !</Alert>  : ""}
                            { resultat === "" ? <Alert  sx={AlertStyle} severity='info'>NODEMAILER NOT WORKING</Alert> : ""}
                            { isLoading && !simulation.netAvImpots ? <CircularProgress color="success" /> : ""}
                </Box>
                {isEmpty === true ? 
                         <Alert severity="error">Veuillez Renseigner les champs requis
                         </Alert>
                :  ""}
        </Box>
    )
}