import axios from "axios";
import { GET_SIMULATION_DATA, SAVE_SIMULATION} from "./ActionTypes";

export const getSimulation = (query) => {

  return async (dispatch) => {
    try {
      if (query === undefined || query.tjm === "0" || query.tps === "0") {
        dispatch({ type: GET_SIMULATION_DATA, payload: "" })
      } else {
        const results = await axios.post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}calcPayslipFromGlobalAmount`, query)
        console.log(results.data)
        localStorage.setItem("simulation",results.data.token)
        dispatch({ type: GET_SIMULATION_DATA, payload: results.data.results });
      }
    } catch (err) { return console.log(err) }
  };
};

export const getSimulationById = (query) => {
  
  return async (dispatch) => {
    const simulation = await axios.post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}getSimulationById`, query);
    if (simulation?.data?.token) localStorage.setItem("simulation",simulation?.data?.token);
    dispatch({type: GET_SIMULATION_DATA, payload: simulation.data.results});
  }
};

export const updateSimulation = (query) => {

  return async (dispatch) => {
    try {
      if (query === undefined || query.tjm === "0" || query.tps === "0") {
        dispatch({ type: GET_SIMULATION_DATA, payload: "" })
      } else {
        const updated = await axios.post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}updatePayslip`, query);
        dispatch({ type: GET_SIMULATION_DATA, payload: updated.data });
      }
    } catch (err) { return console.log(err) }
  };
};

export const saveSimulation = (query) => {

  return async (dispatch) => {
    try {
      if (query === undefined || !query?.name || !query?.email || !query?.token) {
        return await dispatch({type: SAVE_SIMULATION, payload: {data:[], saved:false}})
      }
      const save = await axios.post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}saveCalcPayslipFromGlobalAmount`, query);
      await dispatch({type: SAVE_SIMULATION, payload: {data:save.data,saved:true}});
    } catch(e) {
      console.log(e);
    }
  }
}

