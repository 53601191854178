import { useState } from "react";
import { Card,Typography } from "@mui/material/";
import { useDispatch } from "react-redux";
import { updateSimulation } from "../store/actions/SimulationActions";
import { OptimisationSliderAtom,PeeSliderAtom,PerSliderAtom,CeSliderAtom }  from '../atoms';
import { styles } from "./StyleMolecule"; 

export const SlidersMolecule = ({simulation}) => {

  const dispatch = useDispatch();
  
  let simResult = simulation;
  
  const [formData, setFormData] = useState({});
  const [peeValue, setPeeValue] = useState(simResult.pee);
  const [ceValue, setCeValue] = useState(simResult.ce);
  const [perValue, setPerValue] = useState(simResult.per);
  const [globalValue, setGlobalValue] = useState(0)

  const handleChangeAll = (event, newValue) => {
    if (event.target.name === "pee") { setPeeValue(newValue) }
    else if (event.target.name === "per") { setPerValue(newValue) }
    else if (event.target.name === "ce") { setCeValue(newValue) }
    else if (event.target.name === "opti") {
      setGlobalValue(newValue)
      setPeeValue((simResult.maxPee * globalValue) / 100)
      setPerValue((simResult.maxPer * globalValue) / 100)
      setCeValue((simResult.maxCe * globalValue) / 100)
    }
    setFormData({ tjm: simResult.tjm,frais: simResult.frais,tps: simResult.tps,
      tauxImpots: simResult.tauxImpots,detailed: "false", pee: Number(peeValue), ce: Number(ceValue), per: Number(perValue), token: localStorage.getItem('simulation') })
  };

  const handleSubmit = async (event) => {
    await event.preventDefault();
    dispatch(updateSimulation(formData))
  };

  let SliderOnMainPage = true;

  return (
        <Card sx={[styles.bgSecondary,styles.mb2,{height:300,border:2,borderColor:'white'}]} >
        {SliderOnMainPage === true ? <>
                  <OptimisationSliderAtom handleSubmit={handleSubmit} globalValue={globalValue} handleChangeAll={handleChangeAll}    />
              { simResult.gain !== 0 ? <Typography textAlign='center' color='white' fontWeight='bold' margin={2}>Gain supplémentaire:   {simResult.gain}€</Typography> : ""}
               </>  :
              ( <>
                  <PeeSliderAtom handleSubmit={handleSubmit} peeValue={peeValue} maxPee={simResult.maxPee} handleChangeAll={handleChangeAll} />
                  <PerSliderAtom handleSubmit={handleSubmit} perValue={perValue} maxPer={simResult.maxPer} handleChangeAll={handleChangeAll} />
                  <CeSliderAtom  handleSubmit={handleSubmit} ceValue={ceValue} maxCe={simResult.maxCe} handleChangeAll={handleChangeAll} />
                  </>
              )}
        </Card>
  );
}
