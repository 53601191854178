import { Form,Result } from '../organisms';
import { Box } from '@mui/material';

const Home = () => { 

    return (
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'rgb(16,23,96)',width:'100vw',marginTop:3}}>
                <Result />
                <Form />
        </Box>
        );
};

export default Home;
