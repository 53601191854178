import React from "react";
import { Paper,Box,Typography,Modal,Table,TableContainer,TableHead,TableRow,TableBody, TableCell,Button } from "@mui/material/";
import { ApercuModalStyle,modalInfosStyle,styles } from "../../molecules/StyleMolecule";
import { ReactComponent as LogoGw } from "../../logo-GW-white.svg";

function createData(data, values,color) { return { data, values,color } };

export const ApercuModalAtom = ({simulation}) => { 

  const rows = [
    createData("Tjm Consultant ", `${simulation.tjm}€`,'#52af77' ), createData("Taux de gestion GW ", `${simulation.periodPercent}%`,'red'),
    createData("Nb de jours facturés", `${simulation.tps} jours`,'blue'), createData("Frais de gestion GW", `${simulation.commercialFee}€`,'red'),
    createData("Solde du consultant", `${simulation.solde}€`,'#52af77'), createData("Compte de reserve brut*", `${simulation.reserve}€`,'#52af77'),
    createData("Frais du consultant", `${simulation.frais}€`,'red'),createData("Charges patronales", `${simulation.totalEmployerCharges}€`,'red'),
    createData("Charges salariales", `${simulation.totalEmployeeCharges}€`,'red'),createData("SALAIRE NET", `${simulation.netPayBeforeIT}€`,'#52af77'),
    createData("Plan épargne entreprise", `${simulation.peeAmount}€`,'#52af77'),createData("Plan épargne retraite", `${simulation.perAmount}€`,'#52af77'),
    createData("Comité d'entreprise", `${simulation.ceAmount}€`,'#52af77'),createData("Net Versé", `${simulation.totalPaid.toFixed(2)}€`,'green')
  ];
    
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      { simulation.netPayBeforeIT ?
        <Box>
          <Button fullWidth variant='contained' sx={[styles.bgPrimary,styles.bold,styles.bs10,styles.mb1,styles.hover]} onClick={handleOpen}>Aperçu détaillé</Button>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box component="form" noValidate autoComplete="off" sx={modalInfosStyle}>
              <Box className="pt-4"><LogoGw  height={30} /></Box>
              <Typography id="modal-modals-title" variant="h6" sx={ApercuModalStyle.modalTitle} component="h2">Aperçu détaillé des résultats de la simulation</Typography>
              <Box className="align">
                <TableContainer component={Paper} sx={ApercuModalStyle.maxHeight}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Données</TableCell>
                          <TableCell sx={ApercuModalStyle.center} >Valeurs</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.data}>
                            <TableCell component="th" scope="row">{row.data}</TableCell>
                            <TableCell sx={{color:row.color}} align="center">{row.values}</TableCell>
                          </TableRow> ))}
                      </TableBody>
                    </Table>
                </TableContainer> 
              </Box>
            </Box>
          </Modal>
        </Box> : "" }
    </Box>
  );
}
