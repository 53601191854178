import { Slider,Box, Typography } from '@mui/material';
import { OptimisationSliderStyle } from '../../molecules/StyleMolecule';
import { styles } from '../../molecules/StyleMolecule';

export const OptimisationSliderAtom = (props) => {

    const marksAll = [{ value: 0, label: '0%' },{ value: 50, label: '50%' }, { value: 100, label: 'max %' }];

    function valueLabelFormat(value) {
            const units = ['%'];
            let unitIndex = 0;
            let scaledValue = value;
            return `${scaledValue} ${units[unitIndex]}`;
    };

    return (
        <Box>
             <Typography margin={2} textAlign='center' color='rgb(16,23,96)' sx={[styles.bs10,{backgroundColor:'white'}]} marginBottom={4} borderRadius={10} fontWeight={'bold'}>Optimisation GW</Typography>
             <Box sx={styles.center}>
                    <Slider name="opti" valueLabelDisplay='auto' valueLabelFormat={valueLabelFormat} min={0} max={100} marks={marksAll} value={props.globalValue} onChange={props.handleChangeAll} onChangeCommitted={props.handleSubmit} sx={OptimisationSliderStyle}/>
             </Box>  
        </Box> 
    );
}