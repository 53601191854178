import * as Actions from '../actions/ActionTypes';

const initialState = { data: []};
const saveInitialState = { data: [] };

export function SimulationReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case Actions.GET_SIMULATION_DATA:
            return { data: action.payload }
    }
}

export function SaveReducer(state = saveInitialState, action) {
    switch (action.type) {
        default:
            return state;
        case Actions.SAVE_SIMULATION:
            return { data: action.payload }
    }
}