import {  combineReducers, applyMiddleware } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { SimulationReducer,SaveReducer } from './reducers/SimulationReducer';

const rootReducer = combineReducers({
      simulation: SimulationReducer,
      save: SaveReducer
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))