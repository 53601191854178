import { Typography,Card } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction';
import { styles } from '../StyleMolecule';

export const ResultParametersMolecule = ({simulation}) => {
    return (
        <Card sx={[styles.bgSecondary,{height:300,border:2,borderColor:'white'}]}>
                <Typography boxShadow={10} borderRadius={7} margin={2} color='rgb(16,23,96)' fontWeight={'bold'} backgroundColor='white' textAlign='center'>Paramètres<ConstructionIcon  /></Typography>
                <Typography color='white' fontWeight='bold' margin={2}>Taux journalier moyen : <span className='text-secondary-background'>{simulation.tjm + "€"}</span></Typography>
                <Typography color='white' fontWeight='bold' margin={2}>Frais profesionnels : <span className='text-secondary-background'>{simulation.frais + "€"}</span></Typography> 
                <Typography color='white' fontWeight='bold' margin={2}>Temps facturé : <span className='text-secondary-background'>{simulation.tps + " jours"}</span></Typography> 
                <Typography color='white' fontWeight='bold' margin={2}>Taux d'imposition : <span className='text-secondary-background'>{simulation.tauxImpots}%</span></Typography> 
                <Typography color='white' fontWeight='bold' margin={2}>Chiffre d'affaires : <span className='text-secondary-background'>{simulation.ca}€</span></Typography> 
        </Card>
    )
}