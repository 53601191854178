import EuroTwoToneIcon from "@mui/icons-material/EuroTwoTone";
import { TextField,Box,Button } from "@mui/material";
import { FeesFieldAtomStyle,styles } from "../../molecules/StyleMolecule";

export const FeesFieldAtom = ({handleChange,frais}) => {
    return (
          <Box sx={{color:'rgb(16,23,96)',display:'flex',flexDirection:'column',marginRight:5}} >
                    <label className="text-xl pr-10 font-bold" htmlFor="frais">Frais Pros</label>
                    <Box>
                        <TextField type="number" name="frais" className="bg-white font-bold rounded text-primary-background " onChange={handleChange} id="frais" variant="standard" value={frais} sx={FeesFieldAtomStyle}/>
                        <EuroTwoToneIcon className="text-primary-background" />
                    </Box>
                    <Box className="flex pb-5">
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{borderRadius:7,boxShadow:10,marginTop:1}]} name="frais" value={100} onClick={handleChange}>100€</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="frais" value={300} onClick={handleChange}>300€</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="frais" value={1000} onClick={handleChange}>1000€</Button>
                    </Box>
          </Box>
    )
}