import { Box } from '@mui/material';
import { useState  } from 'react';
import Nav from '../components/layoutComponents/Nav';

const MainLayout = ({ children }) => {
      const [title] = useState('Calculator')
      const [navlink] = useState(['Test'])
      return (<Box sx={{height:'100vh',backgroundColor:'rgb(16,23,96)'}}><Nav title={title} link={navlink} />{ children }</Box>)
}

export default MainLayout;