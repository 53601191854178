import { SlidersMolecule,MenuMolecule,ResultResultsMolecule,ResultParametersMolecule } from '../molecules';
import { getSimulationById,getSimulation,saveSimulation } from '../store/actions/SimulationActions';
import { useSelector,useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { useEffect } from 'react';

export const Result = () => {
    const dispatch = useDispatch();
    const simulation = useSelector((state) => state.simulation.data);

    const handleReset = () => {
        localStorage.removeItem('simulation');
        dispatch(getSimulation())
        dispatch(saveSimulation());
    };


    useEffect(() => {
     if (!simulation?.netPayBeforeIT && localStorage.getItem('simulation')) {
        dispatch(getSimulationById({token:localStorage.getItem('simulation')}));
     }
    },[dispatch,simulation?.netPayBeforeIT]);

    return (
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            {simulation.netPayBeforeIT
                ?
                    <Box sx={{marginBottom:{sm:2,md:2,lg:2}}}>
                        <Box sx={{display:'flex',flexDirection:{sm:'column',xs:'column',md:'row',lg:'row'},marginBottom:2,maxHeight:{xs:300,sm:300,md:1000,lg:1000},overflow:{xs:'scroll',sm:'scroll',md:'hidden',lg:'hidden'}}}>
                            <Box sx={{marginRight:{xs:0,sm:0,md:2,lg:2},width:300}}>
                                <ResultResultsMolecule simulation={simulation} />
                            </Box>
                            <Box sx={{width:300}}>
                                <ResultParametersMolecule simulation={simulation} />
                            </Box>
                        </Box>
                        <Box sx={{maxWidth:{xs:300,sm:300,md:1000,lg:1000},maxHeight:{xs:300,sm:300,md:1000,lg:1000},overflow:{xs:'scroll',sm:'scroll',md:'hidden',lg:'hidden'}}}>
                            <SlidersMolecule simulation={simulation} />
                            <MenuMolecule handleReset={handleReset} simulation={simulation} />
                        </Box>
                    </Box>
                :
            ""} 
        </Box>
    )
}

  