import { SaveModalAtom,ApercuModalAtom } from "../atoms/modalAtoms";
import { Card,Typography,Button,Box} from "@mui/material";
import { styles } from './StyleMolecule'; 

export const MenuMolecule = ({simulation,handleReset}) => {
    return (
        <Card sx={[{height:300,padding:0.4}]}>
            <Typography margin={2} textAlign='center' color='white' sx={[styles.bgSecondary,styles.bs10]} marginBottom={4} borderRadius={10} fontWeight={'bold'}>Menu</Typography>
            <Box sx={{margin:2}}>
            <SaveModalAtom simulation={simulation} />
            <ApercuModalAtom simulation={simulation} />
            <Button sx={[styles.bgPrimary,styles.bold,styles.bs10,styles.hover]} fullWidth variant='contained' onClick={handleReset}>Nouvelle Simulation</Button>
            </Box>
        </Card>
    )
}