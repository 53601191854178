import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import { TextField,Box,Button } from "@mui/material/";
import { TimeFieldAtomTextField,styles } from "../../molecules/StyleMolecule";

export const TimeFieldAtom = ({handleChange,tps}) => {
    return (
        <Box sx={{display:'flex',flexDirection:'column',color:'rgb(16,23,96)',marginRight:2,width:200}}>
                <label className="text-xl pr-2  font-bold" htmlFor="tps">Période (jours)</label>
               <Box sx={{display:'flex'}}>
                    <TextField className="bg-white text-primary-background " type="number" name="tps" onChange={handleChange} required id="tps" variant="standard" value={tps} sx={TimeFieldAtomTextField}/>
                    <CalendarTodayTwoToneIcon className="text-primary-background" />
               </Box>
                <Box className="flex pb-5">
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{borderRadius:7,boxShadow:10,marginTop:1}]} name="tps" value={19} onClick={handleChange}>19 J</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="tps" value={20} onClick={handleChange}>20 J</Button>
                            <Button size='small' variant='contained' sx={[styles.bgSecondary,styles.bold,styles.hoverPrimary,{marginLeft:1,borderRadius:7,boxShadow:10,marginTop:1}]} name="tps" value={21} onClick={handleChange}>21 J</Button>
                </Box>
        </Box>
       
    )
}