import {AlertsAtom,CheckboxFieldAtom,FormHeaderAtom,TjmFieldAtom,TimeFieldAtom,FeesFieldAtom} from '../atoms';
import {Box} from '@mui/material';
 
export const FormFieldsMolecule = (props) => {
    return (
        <Box sx={{display:'flex',flexDirection:{md:'column',lg:'column'}}}>
            <AlertsAtom  isLoading={props.isLoading} isEmpty={props.isEmpty} simulation={props.simulation} />
            <FormHeaderAtom />
            <Box sx={{display:'flex',flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},justifyContent:'space-around',padding:5}}>
                    <TjmFieldAtom handleChange={props.handleChange} tjm={props.tjm} />
                    <FeesFieldAtom handleChange={props.handleChange} frais={props.frais} />
                    <TimeFieldAtom handleChange={props.handleChange} tps={props.tps} />
                    <CheckboxFieldAtom handleChange={props.handleChange} check={props.check} />
            </Box>
        </Box>
    )
}