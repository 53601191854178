import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSimulation } from "../store/actions/SimulationActions";
import CalculateIcon from "@mui/icons-material/Calculate";
import { FormFieldsMolecule } from "../molecules";
import { styles } from "../molecules/StyleMolecule";
import { Box, Button } from "@mui/material";

export const Form = () => {

  const simulation = useSelector((state) => state.simulation.data);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({  tjm: "",  frais: "",  tps: "", tauxImpots: "",  ce: "",  pee: "",  per: "", detailed: "false" });
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState();

  const handleChange = (event) => {
    if (event.target.name === "checkbox") {setIsChecked(!isChecked)} 
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsEmpty(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.tjm === "" || formData.tps === "") { setIsEmpty(true); } 
    else {
      dispatch(getSimulation(formData));
      setFormData({ tjm: "", frais: "", tps: "", ce: "", pee: "",  per: "",  tauxImpots: "11" });
      setIsLoading(true)
    }
  };

  return (
    <Box sx={{display:'flex',alignItems:'center',height:'100vh',overflow:'scroll'}}>
      {!simulation.netPayBeforeIT && !localStorage.getItem('simulation') ? 
        <Box sx={{backgroundColor:'white',display:'flex',borderRadius:2,boxShadow:10}}>
                <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box>
                      <FormFieldsMolecule isEmpty={isEmpty} simulation={simulation} isLoading={isLoading} handleChange={handleChange} tjm={formData.tjm} tps={formData.tps} frais={formData.frais} tauxImpots={formData.tauxImpots} check={isChecked} /> 
                      <Box sx={[styles.center,styles.mt2,styles.mb2]}>
                            <Button sx={[styles.bgPrimary,styles.hover,styles.bs10,styles.bold,styles.white]}  type="submit">Calculer Mes Revenu <CalculateIcon /></Button> 
                      </Box>       
                    </Box>
                 </form>             
        </Box>
      : "" }
  </Box>);
};


