import { NavLink } from 'react-router-dom';
import { Box } from '@mui/system';
import { ReactComponent as LogoGw } from '../../../src/logo-GW-white.svg'

const Nav = () => {
    return (
        <Box sx={{paddingTop:3}}>
            <NavLink to={'/'}>{<LogoGw height={40}  />}</NavLink>    
        </Box>
    )
}
export default Nav;