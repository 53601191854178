import { Card, CardContent, Typography,Chip } from "@mui/material";
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import { styles } from "../StyleMolecule";

export const ResultResultsMolecule = ({simulation}) => {
    return (
        <Card sx={[styles.mb2,{height:300}]}>
                <CardContent>  
                    <Typography  textAlign='center' color='white' sx={[styles.bgSecondary,styles.bs10]} marginBottom={2} borderRadius={10} fontWeight={'bold'}>Résultats <SummarizeTwoToneIcon /></Typography>
                    <Typography color={'rgb(16,23,96)'} fontSize={14} marginBottom={1} fontWeight={'bold'} borderRadius={5} padding={0.4}>Salaire Brut : <Chip sx={[styles.bgSecondary,styles.white]} label={`~${simulation.brut + "€"}`} /></Typography>
                    <Typography color={'rgb(16,23,96)'} fontSize={14} marginBottom={1} fontWeight={'bold'} borderRadius={5} padding={0.4}>Salaire Net : <Chip sx={[styles.bgSecondary,styles.white]} label={`~${simulation.netPayBeforeIT + "€"}`} /></Typography>     
                    <Typography color={'rgb(16,23,96)'} fontSize={14} marginBottom={1} fontWeight={'bold'} borderRadius={5} padding={0.4}>Salaire Net - Impôts : <Chip sx={[styles.bgSecondary,styles.white]} label={`~${simulation.netPayAfterIT + "€"}`} /></Typography>                             
                    <Typography color={'rgb(16,23,96)'} fontSize={14} marginBottom={1} fontWeight={'bold'} borderRadius={5} padding={0.4}>Taux de restitution : <Chip sx={[styles.bgSecondary,styles.white]} label={`${simulation.tauxRest}%`} /></Typography>
                    <Typography color={'rgb(16,23,96)'} fontSize={14} marginBottom={1} fontWeight={'bold'} borderRadius={5} padding={0.4}>Net Versé + cpte de réserve : {Number((simulation.totalPaid + simulation.reserve)).toFixed(2)}€</Typography>
                </CardContent>
     </Card>
    )
}