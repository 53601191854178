import { useNavigate,useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect,useState } from 'react';
import { Card,TextField,Box, Typography,Button,CircularProgress } from '@mui/material';
import { getSimulationById } from '../store/actions/SimulationActions';

export default function SimulatorPage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    
    const [email,setEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
         if (id[0]+id[1] !== 'ey') navigate('/')
    }, [navigate,id]);

    const submit = () => {
        setLoading(true);

        if (email === '') {
            setLoading(false);
            return setError(true);
        } 
        setError(false);
        dispatch(getSimulationById({token:id,email}));
        setLoading(false);
        navigate('/');
    }

   return (
       <Box sx={{backgroundColor:'rgb(16,23,96)',height:"100vh",display:'flex',justifyContent:'center',alignItems:'center'}}>
        { loading
            ? <CircularProgress />
            :
              <Card sx={{boxShadow:10,backgroundColor:'white',padding:3,display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    {error ? <Typography>Merci de renseigner votre addresse mail</Typography> : ''}
                    <Typography color={'rgb(250,16,105)'} marginBottom={3} fontWeight={'bold'}>Entrez votre addresse mail pour accéder à votre simulation !</Typography>
                    <TextField onChange={(e) => setEmail(e.target.value)} label='Adresse Email' />
                    <Button onClick={() => submit()} variant='contained' sx={{backgroundColor:'rgb(16,23,96)','&:hover':{background:'rgb(250,16,105)'},marginTop:2}}>Accédez à votre simulation</Button>
                </Card>
        }
       </Box>
   
   )
}