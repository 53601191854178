import { ReactComponent as LogoGw } from "../../../src/logo-GW-white.svg";
import { Box,Typography } from '@mui/material';

export const FormHeaderAtom = () => {
  return (
    <Box sx={{paddingBottom:10,paddingTop:10,display:{sm:'none',xs:'none',md:'flex',lg:'flex'},justifyContent:'center',alignItems:'center',flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'}}}>
      <Box sx={{backgroundColor:'rgb(16,23,96)',boxShadow:10,padding:2,marginRight:4,borderRadius:5}}>
        <LogoGw height={30}/>
      </Box>
      <Typography variant='h6' fontSize={'bold'} color='rgb(16,23,96)'>
        Simulez vos revenus chez Gentelman Worker
      </Typography>
    </Box>
  );
}
